<template>
	<div style="height: 100%">
		<template v-if="loading">
			<div class="loader">
				<div uk-spinner="ratio: 4" class="uk-margin-medium-bottom  uk-flex uk-flex-center"></div>
				<span>{{ $store.strings.results_searching }}</span>
			</div>
		</template>
		<template v-else-if="error">
			<div class="error">
				<div uk-icon="icon: warning; ratio: 3"></div>
				<div class="uk-alert uk-alert-danger">
					{{ error }}
				</div>
				<p>{{ $store.strings.general_error }}</p>
				<p>
					<a :href="'mailto:' + $store.strings.general_email">{{ $store.strings.general_email }}</a>
					<br />
					<a :href="'tel:' + $store.strings.general_phone">{{ $store.strings.general_phone }}</a>
				</p>

				<router-link class="uk-button uk-button-primary" tag="button" to="/" onclick="_paq.push(['trackEvent', 'results', 'back']);">
					{{ $store.strings.general_back }}
				</router-link>
			</div>
		</template>
		<template v-else>
			<ViewLayout className="results">
				<template v-slot:body>
					<h1>{{ $store.strings.results_ceramicTitle }}</h1>
					<p v-if="$store.strings.results_ceramicSelect">{{ $store.strings.results_ceramicSelect }}</p>
					<div class="uk-grid-small uk-child-width-1-2 uk-text-center" uk-grid>
						<!-- results -->
						<ResultCard v-for="ceramic in results" :key="ceramic.ceramic.article" @click.native="showSeats(ceramic)">
							<template v-slot:image>
								<img :src="apiBase + ceramic.ceramic.images[0].url" :alt="localizedContent(ceramic.ceramic, $store.lang).name" />
							</template>
							<template v-slot:footer>
								<h3>{{ localizedContent(ceramic.ceramic, $store.lang).name }}</h3>
								<p v-if="ceramic.ceramic.article !== backgroundClass && $store.strings.results_ceramicArticle" class="wc-number">
									{{ $store.strings.results_ceramicArticle.replace('{no}', ceramic.ceramic.article) }}
								</p>
								<p v-if="ceramic.ceramic.article !== backgroundClass" class="wc-description">
									{{ localizedContent(ceramic.ceramic, $store.lang).fullDescription }}
								</p>
							</template>
						</ResultCard>
						<!-- no fit -->
						<ResultCard backgroundColor="#DBDBE5" @click.native="saySorry()">
							<template v-slot:banner>
								<strong>{{ $store.strings.results_ceramicNotIncludedTitle }}</strong>
							</template>
							<template v-slot:footer>
								{{ $store.strings.results_ceramicNotIncludedDescription }}
							</template>
						</ResultCard>
						<div class="spacer"></div>
					</div>
				</template>
				<template v-slot:footer>
					<router-link to="/">
						<button class="uk-button uk-button-primary uk-button-large blue-action-button" onclick="_paq.push(['trackEvent', 'results', 'restart']);">
							{{ $store.strings.results_restart }}
						</button>
					</router-link>
				</template>
			</ViewLayout>
		</template>
	</div>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue'
import ResultCard from '@/components/ResultCard.vue'
import { localizedContent } from '../helper.js'
export default {
	name: 'Result',
	data() {
		return {
			loading: true,
			error: null,
			apiBase: process.env.VUE_APP_API_BASE,
			backgroundClass: 'Background',
			results: []
		}
	},
	components: {
		ViewLayout,
		ResultCard,
	},
	methods: {
		localizedContent,
		saySorry() {
			window._paq.push(['trackEvent', 'results', 'contact'])
			this.$router.push('/sorry')
		},
		showSeats(result) {
			window._paq.push(['trackEvent', 'results', 'selection', result.ceramic.article]);

			if (result.ceramic.seats.length) {
				this.$router.push({
					name: 'Seat',
					params: {
						result,
					},
				})
			}
		},
		fake() {
			// fetch local demo response
			// useful for development
			return fetch('demoresponse.json', {
				'Content-Type': 'application/json',
			}).then(async response => {
				const data = await response.json()
				// check for error response
				if (!response.ok) {
					const error = (data && data.message) || response.statusText
					return Promise.reject(error)
				}
				return data
			})
		},
		analyze() {
			// create new request in strapi and let the Ceramic API analyze it
			return fetch(`${process.env.VUE_APP_API_BASE}/api/request`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(this.$store.request),
			}).then(async response => {
				const data = await response.json()
				// check for error response
				if (!response.ok) {
					const error = (data && data.message) || response.statusText
					return Promise.reject(error)
				}
				return data
			})
		},
		request(id) {
			return fetch(`${process.env.VUE_APP_API_BASE}/api/request/${id}`, {
				'Content-Type': 'application/json',
			}).then(async response => {
				const data = await response.json()
				// check for error response
				if (!response.ok) {
					const error = (data && data.message) || response.statusText
					return Promise.reject(error)
				}
				return data
			})
		},
	},
	async created() {
		if (
			!this.$route.params ||
			!this.$route.params.id
		) { // new request
			// check if inputs are correct
			if (
				!this.$store.request ||
				this.$store.request.cistern === null ||
				!this.$store.request.images.front ||
				!this.$store.request.images.side ||
				!this.$store.request.images.top
			) {
				this.$router.push('/')
				return
			}

			try {
				const data =
					process.env.VUE_APP_USE_DEMO_RESPONSE === 'true'
						? await this.fake()
						: await this.analyze()
				this.loading = false
				this.$store.requestID = data.uid
				this.results = data.predictions

				window._paq.push([ 'trackEvent', 'results', 'matches', this.results.length ])

				this.$router.push(`/result/${data.uid}`)
			} catch (error) {
				console.error('Submission error', error)

				window._paq.push([ 'trackEvent', 'results', 'error' ])

				this.error = error
				this.loading = false
			}
		} else { // existing request
			this.$store.requestID = this.$route.params.id
			try {
				const data =
					process.env.VUE_APP_USE_DEMO_RESPONSE === 'true'
						? await this.fake()
						: await this.request(this.$store.requestID)
				this.loading = false
				this.results = data.predictions
			} catch (error) {
				console.error('Request error', error)

				window._paq.push([ 'trackEvent', 'results', 'error' ])

				this.error = error
				this.loading = false
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.error,
.loader {
	@extend .uk-padding;
	background: #fbfbfb;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}
h3 {
	font-size: 1.1rem;
	font-weight: bold;
	margin: 0;
}
strong {
	font-weight: 700;
}
.wc-number {
	font-size: 12px;
	margin: 3px 0;
	font-weight: 400;
}
.wc-description {
	line-height: 1.8;
	font-size: 10px;
	margin: 0;
}
</style>
